import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/colors';
import useWording from '../../utils/useWording';
import { respondTo } from '../../utils/responsive';
import Container from '../../components/Container';
import ModalBox from '../../components/ModalBox';
import Text from '../../components/Text';

const IngredientsAndCuisine = () => {
  const wording = useWording('ingredients_and_cuisine');
  const [ currentIndex, setCurrentIndex ] = useState(null);
  const headingRef = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', handleSetHeadingOpacity);
    return () => window.removeEventListener('scroll', handleSetHeadingOpacity);
  }, [])

  function handleSetHeadingOpacity() {
    const offsetTop = headingRef.current.offsetTop;
    const offsetHeight = headingRef.current.offsetHeight;
    const currentScroll = window.pageYOffset;
    const percent = (currentScroll) / (offsetHeight + offsetTop);
    headingRef.current.style.opacity = Math.max(1 - percent, 0);
  }

  function handleCloseModal() {
    setCurrentIndex(null)
  }

  function handleClickItem(e) {
    const index = Number(e.currentTarget.dataset.index);
    setCurrentIndex(index);
  }
  
  return (
    <Root>
      <ModalBox open={currentIndex !== null} onClose={handleCloseModal}>
        { currentIndex !== null &&
          <ModalContent>
            <div className="info">
              <Text className="job">{wording.team.list[currentIndex].job}</Text>
              <Text className="title">{wording.team.list[currentIndex].title}</Text>
              <Text className="name">{wording.team.list[currentIndex].name}</Text>
              <Text className="content" dangerouslySetInnerHTML={{ __html: wording.team.list[currentIndex].content }} />
            </div>
            <img className="image" src={wording.team.list[currentIndex].full_icon} alt="" />
          </ModalContent>
        }
      </ModalBox>
      <StickyWrapper>
        <Heading ref={headingRef}>
          <Text dangerouslySetInnerHTML={{ __html: wording.heading }} />
        </Heading>
        <img src="/images/mask-shape.svg" alt="" />
      </StickyWrapper>
      <TeamSection>
        <Text className="title">{ wording.team.title }</Text>
        <Text className="content">{ wording.team.content }</Text>
        <List>
          { wording.team.list.map((item, i) =>
            <li key={i}>
              <Item data-index={i} onClick={handleClickItem} mask={item.icon}>
                <div className="image">
                  <img src={item.icon} alt="" />
                </div>
                <Text className="name">{ item.name }</Text>
                <Text className="job">{ item.job }</Text>
              </Item>
            </li>
          ) }
        </List>
      </TeamSection>
    </Root>
  )
}

const Root = styled(Container)`
  padding-bottom: 90px;
  ${respondTo.md} {
    padding-top: 85px;
  }
`

const StickyWrapper = styled.div`
  position: relative;
  margin-bottom: 200px;
  ${respondTo.md} {
    margin-bottom: 90px;
  }
  > img {
    position: relative;
    padding-top: 50vh;
    display: block;
    width: 100%;
    z-index: 1;
  }
`

const Heading = styled.div`
  position: sticky;
  padding-top: 120px;
  top: 25vh;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  white-space: break-spaces;
  z-index: 0;
  ${respondTo.md} {
    padding-top: 85px;
    padding-bottom: 40px;
    font-size: 22px;
  }
`

const TeamSection = styled.div`
  > .title {
    margin-bottom: 12px;
    font-size: 36px;
    font-weight: 700;
    ${respondTo.md} {
      font-size: 22px;
    }
  }
  > .content {
    margin-bottom: 58px;
    font-size: 18px;
    font-weight: 400;
    white-space: break-spaces;
    ${respondTo.md} {
      font-size: 16px;
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -24px;
  ${respondTo.md} {
    margin: 0 -12px;
  }
  > li {
    margin-bottom: 40px;
    padding: 0 24px;
    width: 25%;
    box-sizing: border-box;
    ${respondTo.md} {
      margin-bottom: 36px;
      width: 50%;
      padding: 0 12px;
    }
  }
`

const Item = styled.div`
  padding: 0 20px;
  text-align: center;
  cursor: pointer;
  ${respondTo.md} {
    padding: 0;
  }
  > .image {
    ${({ mask }) => mask && css`
      mask: url(${mask}) 0 0/100% 100%;
      background: ${colors.black};
      transition: background .3s ease;
    `}
    > img {
      opacity: 0;
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &:hover > .image {
    background: ${colors.orange};
  }
  > .job {
    font-size: 18px;
    font-weight: 400;
    white-space: break-spaces;
    text-align: center;
    ${respondTo.md} {
      font-size: 16px;
    }
  }
  > .name {
    margin-top: 24px;
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 700;
    ${respondTo.md} {
      font-size: 16px;
    }
  }
`

const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 74px 106px;
  width: 1340px;
  max-width: 90vw;
  max-height: 90vh;
  background: ${colors.background};
  box-sizing: border-box;
  overflow-y: auto;
  ${respondTo.md} {
    display: block;
    padding: 20px 21px;
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  > .info {
    width: 475px;
    max-width: 48%;
    ${respondTo.md} {
      margin-bottom: 22px;
      width: 100%;
      max-width: 100%;
    }
    .job {
      font-size: 22px;
      font-weight: 700;
      ${respondTo.md} {
        font-size: 18px;
      }
    }
    .title {
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: 700;
      ${respondTo.md} {
        font-size: 18px;
      }
    }
    .name {
      margin-bottom: 2px;
      font-size: 36px;
      font-weight: 700;
    }
    .content {
      font-size: 18px;
      font-weight: 400;
      white-space: break-spaces;
      ${respondTo.md} {
        font-size: 16px;
      }
    }
  }
  > .image {
    display: block;
    width: 550px;
    max-width: 48%;
    ${respondTo.md} {
      width: 100%;
      max-width: 100%;
    }
  }
`

export default IngredientsAndCuisine;