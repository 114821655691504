import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const AmountCount = ({...props}) => {
  return (
    <Root {...props}>
      2200 / 2222
    </Root>
  )
}

const Root = styled.div`
  margin-bottom: 24px;
  line-height: 1;
  font-weight: 400;
  font-size: 72px;
  text-align: center;
  ${respondTo.md} {
    font-size: 40px;
  }
`

export default AmountCount;